@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  background-color: white !important;
  /* background-color: var(--biketownbeige25) !important; */
}

/* create css vars */
:root {
  --biketownorange: #ef5f31;
  --biketownpurple: #9680fa;
  --biketowngreen: #35bb6a;
  --biketownmustard: #d3b71f;
  --biketownpink: #fbc4f9;
  --biketownlime: #e3f87c;
  --biketownbeige: #f2e2d1;
  --pastelyellow: #FDFD96;
}

/* create rgba css vars */
:root {
  --biketownorange25: rgba(239, 95, 49, 0.25);
  --biketownpurple25: rgba(150, 128, 250, 0.25);
  --biketowngreen25: rgba(24, 252, 115, 0.25);
  --biketownmustard25: rgba(211, 183, 31, 0.25);
  --biketownpink25: rgba(251, 196, 249, 0.25);
  --biketownlime25: rgba(227, 248, 124, 0.25);
  --biketownbeige25: rgba(242, 226, 209, 0.25);
  --pastelyellow25: rgba(253, 253, 150, 0.25);

  --biketownorange50: rgba(239, 95, 49, 0.5);
  --biketownpurple50: rgba(150, 128, 250, 0.5);
  --biketowngreen50: rgba(24, 252, 115, 0.5);
  --biketownmustard50: rgba(211, 183, 31, 0.5);
  --biketownpink50: rgba(251, 196, 249, 0.5);
  --biketownlime50: rgba(227, 248, 124, 0.5);
  --biketownbeige50: rgba(242, 226, 209, 0.5);
  --pastelyellow50: rgba(253, 253, 150, 0.5);

  --biketownorange75: rgba(239, 95, 49, 0.75);
  --biketownpurple75: rgba(150, 128, 250, 0.75);
  --biketowngreen75: rgba(24, 252, 115, 0.75);
  --biketownmustard75: rgba(211, 183, 31, 0.75);
  --biketownpink75: rgba(251, 196, 249, 0.75);
  --biketownlime75: rgba(227, 248, 124, 0.75);
  --biketownbeige75: rgba(242, 226, 209, 0.75);
  --pastelyellow75: rgba(253, 253, 150, 0.75);

  --biketownorange90: rgba(239, 95, 49, 0.9);
  --biketownpurple90: rgba(150, 128, 250, 0.9);
  --biketowngreen90: rgba(24, 252, 115, 0.9);
  --biketownmustard90: rgba(211, 183, 31, 0.9);
  --biketownpink90: rgba(251, 196, 249, 0.9);
  --biketownlime90: rgba(227, 248, 124, 0.9);
  --biketownbeige90: rgba(242, 226, 209, 0.9);
  --pastelyellow90: rgba(253, 253, 150, 0.9);

  
}

@font-face {
  font-family: 'nike-bdcn';
  src: url('/assets/fonts/biketown/TradeGothicforNike365-BdCn.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'nike-light';
  src: url('/assets/fonts/biketown/TradeGothicforNike365-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'nike-roman';
  src: url('/assets/fonts/biketown/TradeGothicforNike365-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'nike-futura';
  src: url('/assets/fonts/biketown/Neufville-Digital-Futura-ND-for-Nike-365-Cn-XBd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* Next button stylings */
.btn.custom-next-button {
  border: solid 1px var(--biketownorange);
  /* border: solid 1px var(--biketownpurple); */
  background-color: #fff;
  border-radius: 20px 20px 20px 20px !important;
  overflow: hidden; /* Keeps child elements inside the border-radius */
  font-weight: bold;
  padding: 4px 0 3px 0 !important;
}
/* Home button stylings */
.btn.custom-home-button {
  
  background-color: var(--biketownorange);
  /* background-color: var(--biketowngreen); */
  border-radius: 20px 20px 20px 20px !important;
  overflow: hidden; /* Keeps child elements inside the border-radius */
  font-weight: bold;
  padding: 4px 0 3px 0 !important;
}
.btn.custom-next-button:disabled {
  /* border: none; */
  background-color: #fff;
  border-radius: 20px 20px 20px 20px !important;
  overflow: hidden; /* Keeps child elements inside the border-radius */
  font-weight: bold;
  padding: 4px 0 3px 0 !important;
}

/* Styles for the button text */
.custom-next-button .button-text {
  color: var(--biketownorange);
  /* color: var(--biketownpurple); */
  background-color: rgba(255, 255, 255, 1);
  padding: 8px 10px; 
}

.custom-home-button .button-text {
  color: var(--biketownorange);
  /* color: var(--biketowngreen); */
  background-color: rgba(255, 255, 255, 1);
  padding: 8px 10px; 
}

.custom-next-button:disabled .button-text {
  color: var(--biketownorange);
  /* color: var(--biketownpurple); */
  background-color: rgba(255, 255, 255, 1);
  padding: 8px 10px; 
}

/* change color to orange on hover */
.custom-next-button:hover .button-text {
  color: var(--biketownorange);
  /* background-color: rgba(255, 255, 255, 1); */
  transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out,box-shadow .5s ease-in-out !important;
  
}

.custom-home-button:hover .button-text {
  color: var(--biketownorange);
  /* background-color: rgba(255, 255, 255, 1); */
  transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out,box-shadow .5s ease-in-out !important;
  
}



/* Next button Icon */
.custom-next-button .button-icon {
  color: rgba(255, 255, 255, 0.75);
  background-color: var(--biketownorange);
  /* background-color: var(--biketownpurple); */
  padding: 8px 12px;
  border-radius: 0 20px 20px 0;
}

/* home button icon */
.custom-home-button .button-icon {
  color: rgba(255, 255, 255, 0.75);
  background-color: var(--biketownorange);
  /* background-color: var(--biketowngreen); */
  padding: 8px 12px;
  border-radius: 0 20px 20px 0;
}

/* Next button disabled icon */
.custom-next-button:disabled .button-icon {
  color: rgba(255, 255, 255, 0.75);
  background-color: white;
  /* background-color: var(--biketownpurple); */
  padding: 8px 12px;
  border-radius: 0 20px 20px 0;
}

/* Styles for the button icon */
.custom-next-button:hover .button-icon {
  color: white;
  /* color: var(--biketownorange); */
  transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out,box-shadow .5s ease-in-out !important;
}

/* Home button icon hover */
.custom-home-button:hover .button-icon {
  color: white;
  transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out,box-shadow .5s ease-in-out !important;
}

p {
  font-family: 'nike-roman', sans-serif;
  letter-spacing: -0.5px;
  line-height: 1.4 !important;
}


ul, ol {
  font-family: 'nike-roman', sans-serif;
  letter-spacing: -0.5px;
  line-height: 1.4 !important;
  /* body orange */
}
h1, h2, h3, h4, h5, h6 {
  /* color: var(--biketownpurple); */
  font-family: 'nike-bdcn', sans-serif;
  letter-spacing: -0.5px;
  line-height: 1.4 !important;
}

b, strong {
  font-family: 'nike-roman', sans-serif;
  letter-spacing: -0.5px;
  line-height: 1.4 !important;
}
label {
  font-family: 'nike-roman', sans-serif;
  /* letter-spacing: -0.5px;
  line-height: 1.4 !important; */
}

.course-content {
  /* General reset styles for the course content area */
  line-height: normal;
  font-size: medium;
  font-weight: normal;
  color: black;
  /* font-family: serif; */
}

/* Progress bar */
.progress {
  background-color: var(--biketownbeige50) !important;
}

.fa.fa-fw.fa-check.text-success {
  color: var(--biketowngreen) !important;
}

.fa.fa-fw.fa-unlock.text-success {
  color: var(--biketowngreen) !important;
}

.fa.fa-fw.fa-lock.text-danger {
  color: var(--biketownmustard) !important;
}

/* logo icon to the right */
.page-header-dark #page-header .btn-alt-secondary, .sidebar-dark #sidebar .btn-alt-secondary {
  color: white !important;
  background-color: var(--biketownorange) !important;
  border-color: var(--biketownorange) !important;
}

/* avatar dropdown button */
.page-header-dark #page-header .btn-alt-secondary, .sidebar-dark #sidebar #page-header-user-dropdown.btn-alt-secondary {
  color: white !important;
  /* make background color completely transparent */
  background-color: rgba(251, 196, 249, 0) !important;
  border-color: rgba(251, 196, 249, 0) !important;
}


div#page-container.sidebar-dark #sidebar {
  color: black;
  background-color: var(--biketownorange) !important;
}

/* Main top header, but not the left corner */
#page-container.page-header-dark #page-header {
  color: white !important;
  /* color: #d1d8de; */
  background-color: var(--biketownorange) !important;
  /* background-color: white !important; */
}

/* top left rectangle thing */
#page-container.sidebar-dark #sidebar>.content-header {
  background-color: var(--biketownorange) !important;
  /* background-color: white !important; */
}

/* main logo name top left */
.page-header-dark #page-header .text-dual, .sidebar-dark #sidebar .fw-semibold.text-dual {
  color: white !important;
}

/* nav active links */
.nav-main-dark .nav-main-link, .page-header-dark #page-header .nav-main-link, .sidebar-dark #sidebar a.nav-main-link.active {
  color: white !important;
  font-weight: bold !important;
  /* underline font */
  text-decoration: underline !important;
}

/* nav inactive link */
.nav-main-dark .nav-main-link, .page-header-dark #page-header .nav-main-link, .sidebar-dark #sidebar a.nav-main-link {
  color: white !important;
  font-weight: bold !important;
  /* color: var(--biketownbeige50) !important; */
}

/* nav icons */
.nav-main-dark .nav-main-link>.nav-main-link-icon, .page-header-dark #page-header .nav-main-link>.nav-main-link-icon, .sidebar-dark #sidebar .nav-main-link>i.nav-main-link-icon {
  color: white !important;
  font-weight: bold !important;
}

/* navigation categories */
.nav-main-dark .nav-main-heading, .page-header-dark #page-header .nav-main-heading, .sidebar-dark #sidebar .nav-main-heading {
  color: black !important;
  /* color: var(--biketownlime) !important; */
  font-weight: bold !important;
}

.table {
  --bs-table-striped-bg: rgba(255,255,255,0.5) !important;
  /* --bs-table-striped-bg: #f6f7f9; */
}

.block-content {
  /* background-color: white; */
  background-color: var(--biketownbeige25);
}

/* find table with direct parent div with class of .block-content */
div.block-content table {
  background-color: var(--biketownbeige) !important;
}

.block-header-default {
  background-color: var(--biketownbeige90) !important;
}

div.bg-body-extra-light {
  background-color: rgba(255,255,255,0.5) !important;
}
footer.bg-body-dark {
  background-color: black !important;
  color: white !important;
}

div.bg-primary-dark-op {
  background-color: rgba(255,255,255,0.25) !important;
}

div.progress-bar.bg-info {
  background-color: var(--biketownpurple) !important;
}

.btn-success {
  color: #fff;
  background-color: var(--biketowngreen) !important;
  border-color: var(--biketowngreen) !important;
}


.course-content ul, .course-content ol {
  list-style-type: disc; /* 'disc' for unordered, 'decimal' for ordered */
  margin-left: 1em;
  padding-left: 1em;
}

.course-content p {
  margin-top: 1em;
  margin-bottom: 1em;
}

.course-content h1, .course-content h2, .course-content h3, .course-content h4, .course-content h5, .course-content h6 {
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.course-content h1 {
  font-size: 2em;
}

.course-content h2 {
  font-size: 1.5em;
}

.course-content h3 {
  font-size: 1.17em;
}

.course-content h4 {
  font-size: 1.12em;
}

.course-content h5 {
  font-size: .83em;
}

.course-content h6 {
  font-size: .75em;
}

.course-content a {
  color: blue;
  text-decoration: underline;
}

.course-content img {
  max-width: 100%;
  height: auto;
}

.course-content table {
  border-collapse: collapse;
  border-spacing: 0;
}

.course-content th, .course-content td {
  border: 1px solid black;
  padding: 0.5em;
}

.course-content blockquote {
  margin: 0;
  padding-left: 1em;
  border-left: 2px solid grey;
}

.course-content img {
  max-width: 100%;
  height: auto;
  /* Reset any margin or padding that might be applied */
  margin: 0;
  padding: 0;
  /* Remove display block if you want to allow images to be inline by default */
  display: block;
  /* Center the image */
  margin-left: auto;
  margin-right: auto;
}

/* Add additional elements and their default styles as needed */


@keyframes quiet {
  25%{ transform: scaleY(.6); }
  50%{ transform: scaleY(.4); }
  75%{ transform: scaleY(.8); }
}
@keyframes normal {
  25%{ transform: scaleY(1); }
  50%{ transform: scaleY(.4); }
  75%{ transform: scaleY(.6); }
}
@keyframes loud {
  25%{ transform: scaleY(1); }
  50%{ transform: scaleY(.4); }
  75%{ transform: scaleY(1.2); }
}
#vocal-print-sound-wave{
  display: flex;
  justify-content: center;
  margin: 0;padding: 0;
  align-items: center;
  height: 40vh;
}
.soundboxContainer{
  display: flex;
  justify-content: space-between;
  height: 64px;
  --boxSize: 8px;
  --gutter: 4px;
  width: calc((var(--boxSize) + var(--gutter)) * 5);
}
.soundbox{
  transform: scaleY(.4);
  height: 100%;
  width: var(--boxSize);
  background: black;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 8px;
}
.soundbox1{ animation-name: quiet; }
.soundbox2{ animation-name: normal; }
.soundbox3{ animation-name: quiet; }
.soundbox4{ animation-name: loud; }
.soundbox5{ animation-name: quiet; }